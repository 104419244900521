exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-delavnice-tsx": () => import("./../../../src/pages/delavnice.tsx" /* webpackChunkName: "component---src-pages-delavnice-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-varnost-tsx": () => import("./../../../src/pages/varnost.tsx" /* webpackChunkName: "component---src-pages-varnost-tsx" */),
  "component---src-pages-vesoljska-mreza-tsx": () => import("./../../../src/pages/vesoljska-mreza.tsx" /* webpackChunkName: "component---src-pages-vesoljska-mreza-tsx" */),
  "component---src-pages-visokovanje-tsx": () => import("./../../../src/pages/visokovanje.tsx" /* webpackChunkName: "component---src-pages-visokovanje-tsx" */),
  "component---src-pages-za-zacetnike-tsx": () => import("./../../../src/pages/za-zacetnike.tsx" /* webpackChunkName: "component---src-pages-za-zacetnike-tsx" */),
  "component---src-templates-prirocnik-tsx": () => import("./../../../src/templates/prirocnik.tsx" /* webpackChunkName: "component---src-templates-prirocnik-tsx" */)
}

